<template>
  <v-container fluid class="grey lighten-3">

    <v-row class="inner-content">
      <v-col>
        <ProvidersTable />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: 'Providers',

  components: {
    ProvidersTable: () => import('@/components/tables/ProvidersTable.vue')
  },
}
</script>
